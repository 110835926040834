.home-page-sub-title {
    width: 600px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 120%;
    color: #27262D;
    padding: 100px 0 90px;
}

.text-logo {
    padding: 120px 0;
}

.line {
    width: 100%;
    height: 1px;
    background: rgba(39, 38, 45, 0.15);
}

.home-page-show-real-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.show-real-button {
    display: flex;
    align-items:center;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 0;
    color: #27262D;
    text-transform: uppercase;
    margin-right: 10px;
    transition: 400ms;
    cursor: pointer;
    outline: none;
}
.show-real-button img {

    transition: 400ms ease-in-out;
    margin-left: 20px;
}
.show-real-button:hover img{
    margin-left: 50px;
}

@media screen and (max-width: 900px) {
    .home-page-sub-title {
        padding: 140px 0 60px;
        font-size: 22px;
    }
    .text-logo {
        padding: 60px 0;
        width: 70vw;
    }
}
