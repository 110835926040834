.feedBackForm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
 }

.feedBackForm .title {
    font-family: TT Commons;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 120%;
}

.formInputs {
    display: flex;
    flex-direction: column;
}

.formInputs input {
    padding: 27px 0;
    border: none;
    background: transparent;
    outline: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 40px;
}

.formInputs textarea {
    border: none;
    background: transparent;
    outline: none;
    resize: none;
    height: 121px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 45px;
    padding: 20px;
    box-sizing: border-box;
}

.textArea label {
    display: block;
    margin: 27px 0 18px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
}

.formInputs button {
    font-family: TT Commons;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 49%;
    transition: 400ms;
    background: transparent;
    border: none;
    width: 360px;
    display: flex;
    padding: 0;
    cursor: pointer;
}

.formInputs svg {
    margin-left: 20px;
    transition: 400ms;
}

.formInputs button path {
    transition: 400ms;
}

.formInputs button:hover {
    color: #9c9c9c;
}

.formInputs button:hover svg {
    color: #9c9c9c;
    margin-left: 60px;
}

.formInputs button:hover path {
    fill: #9c9c9c;
}

/*Стили белой формы*/

.feedBackForm-white {
    background: white;
    margin-bottom: 200px;
}

.feedBackForm-white .title {
    color: #27262D;
}

.feedBackForm-white .formInputs input {
    color: #27262D;
    border-bottom: 1px solid #27262D;
}

.feedBackForm-white .formInputs textarea {
    color: #27262D;
    border: 1px solid #27262D;
}

.feedBackForm-white .textArea label {
    color: #27262D;
}

.feedBackForm-white .formInputs input::placeholder {
    color: #27262D;
}

.feedBackForm-white .formInputs textarea::placeholder {
    color: #27262D;
}

.feedBackForm-white .formInputs button {
    color: #27262D;
}

.feedBackForm-white .formInputs button path {
    fill: #27262D;
}

/*Стили черной формы*/

.feedBackForm-black {
    background: #27262D;
}

.feedBackForm-black .title {
    color: #FFFFFF;
}

.feedBackForm-black .formInputs input {
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.feedBackForm-black .formInputs textarea {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.feedBackForm-black .textArea label {
    color: #FFFFFF;
}

.feedBackForm-black .formInputs input::placeholder {
    color: #FFFFFF;
}

.feedBackForm-black .formInputs textarea::placeholder {
    color: #FFFFFF;
}

.feedBackForm-black .formInputs button {
    color: #FFFFFF;
}

.feedBackForm-black .formInputs button path {
    fill: #FFFFFF;
}

@media screen and (max-width: 1200px) {
    .feedBackForm {
        padding-top: 80px;
    }

    .feedBackForm .title {
        text-align: center;
        font-size: 62px;
        padding-bottom: 20px;
    }

    .formInputs {
        width: calc(100% - 40px);
        margin: 0 auto;
    }
}

@media screen and (max-width: 900px) {

    .feedBackForm .title {
        font-size: 50px;
    }
}

@media screen and (max-width: 600px) {
    .feedBackForm .title {
        font-size: 32px;
    }

    .formInputs input {
        padding: 13px 0;
        font-size: 18px;
        margin-bottom: 17px;
    }

    .textArea label {

        margin: 10px 0 18px 0;
        font-size: 18px;
    }

    .formInputs textarea {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .feedBackForm-black .formInputs button {
        width: 62%;
        font-size: 30px;
        line-height: 71%;
        padding-bottom: 10px;
    }

    .feedBackForm-white .formInputs button {
        width: 62%;
        font-size: 30px;
        line-height: 71%;
        padding-bottom: 10px;
    }

    .feedBackForm {
        margin: 0 auto;
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 400px) {
    .feedBackForm .title {
        font-size: 26px;
    }

    .textArea label {
        font-size: 13px;
    }

    .formInputs svg {
        width: 18px;
    }

    .feedBackForm-black .formInputs button {
        font-size: 21px;
        line-height: 130%;
    }

    .feedBackForm-white .formInputs button {
        font-size: 21px;
        line-height: 130%;
    }

    .feedBackForm {
        padding-top: 40px;
    }
}
