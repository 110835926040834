.contactsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactsHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 90px;
}

.ContactsHeaderTitle {
    font-family: TTNormsBold;
    font-style: normal;
    font-weight: 1000;
    font-size: 90px;
    line-height: 120%;
    color: #27262D;
    padding-right: 295px;
    text-transform: uppercase;
}

.ContactsHeaderAttributsWrapper {
    padding: 100px 0;
}

.ContactsHeaderAttributTitle {
    font-family: TT Norms;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: #27262D;
}

.WrapperDescriptions:first-child {
    margin-bottom: 32px;
}

.WrapperDescriptions:last-child {
    margin-top: 32px;
}

.ContactsHeaderAttributDescription {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #27262D;
}

@media screen and (max-width: 1200px) {
    .contactsHeaderWrapper {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }

    .ContactsHeaderTitle {
        font-size: 62px;
    }

    .ContactsHeaderAttributsWrapper {
        padding: 40px 0;
    }
}

@media screen and (max-width: 900px) {
    .ContactsHeaderTitle {
        font-size: 56px;
        padding-right: 207px;
    }

    .ContactsHeaderAttributDescription {
        font-size: 18px;
        line-height: 100%;
    }
}

@media screen and (max-width: 800px) {
    .contactsHeader {
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    .ContactsHeaderTitle {
        font-size: 42px;
    }

    .ContactsHeaderAttributDescription {
        font-size: 16px;
    }

    .ContactsHeaderAttributsWrapper {
        margin-right: 114px;
    }
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 400px) {
    .ContactsHeaderTitle {
        font-size: 36px;
    }

    .contactsHeaderWrapper {
        justify-content: center;
        padding-right: 0;
    }

    .ContactsHeaderAttributsWrapper {
        margin-right: 0;
    }
}
