.strategy {
    display: grid;
    align-items: center;
    border-bottom: 1px solid #27262d26;
    padding-bottom: 117px;
    padding-top: 90px;
}

.strategyTitle {
    font-family: "TT CommonsBold";
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #27262D;
    margin-right: 40px;
}

.strategyDescription {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #27262D;
}

@media screen and (max-width: 1200px) {
    .strategy {
        display: grid;
        grid-template-columns: minmax(1fr,200px) 2fr;
        padding-bottom: 60px;
        margin-bottom: 0;
    }

    .strategyTitle {
        font-size: 56px;
    }

    .strategyDescription {
        font-size: 16px;
    }
}

@media screen and (max-width: 900px) {
    .strategy {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        padding-top: 50px;
    }

    .strategyTitle {
        font-size: 42px;
    }

    .strategyDescription {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .strategyTitle {
        font-size: 34px;
        text-align: center;
        padding: 10px 20px;
        margin-right: 0;
    }

    .strategyItem {
        margin-bottom: 50px!important;
    }
}
