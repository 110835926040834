.ourProjects {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ourProjects .cardProject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
    width: 100%;
}

.cardsWrapper {
    margin-bottom: 200px;
}

.ourProjectTitle {
    font-family: TTNormsBold;
    font-style: normal;
    font-weight: 800;
    font-size: 90px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #27262D;
    margin: 208px 0;
}

.cardProject .wrapperDescription:nth-child(odd) {
    margin-right: 40px;
}

.cardProject .wrapperDescription:nth-child(even) {
    margin-left: 40px;
}

.wrapperDescription .title {
    font-family: TTNormsBold;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 115%;
    color: #27262D;
    padding-bottom: 40px;
}

.wrapperDescription .subTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #27262D;
    padding-bottom: 40px;
}

.wrapperDescription .linkWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    cursor: pointer;
}

.wrapperDescription .linkWrapper .link {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FF371C;
    transition: 400ms;
}

.wrapperDescription .linkWrapper .link:hover {
    margin-right: 30px;
}

.wrapperDescription .linkWrapper img {
    width: 13px;
    height: 13px;
    margin-left: 10px;
}

.wrapperDescription .path {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #27262D;
}

@media screen and (max-width: 1400px) {
    .ourProjects {
        padding: 0 20px;
    }

    .cardProject img {
        max-width: 500px;
    }

}

@media screen and (max-width: 1000px) {
    .cardsWrapper {
        margin-bottom: 100px
    }
    .ourProjectTitle {
        font-size: 60px;
        text-align: center;
        margin:110px 0 60px;
    }
    .wrapperDescription .title {
        margin-top: 40px;
    }
    .cardProject .wrapperDescription:nth-child(odd) {
        margin: 0;
    }
    .ourProjects {
        padding: 0 20px;
    }
    .ourProjects .cardProject {
        margin-bottom: 60px;
    }
    .cardProject img {
        max-width: 100%;
        width: 100%;
    }
    .cardProject .wrapperDescription:nth-child(even) {
        margin: 0;
    }
    .cardProject .wrapperDescription {
        order: 2;
    }

    .cardProject {
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 40px);
    }

}

/*@media screen and (max-width: 1200px) {*/

/*    .cardsWrapper {*/
/*        display: flex;*/
/*        align-items: center;*/
/*        flex-direction: column;*/
/*        margin-bottom: 0;*/
/*    }*/

    /*.cardProject img {*/
    /*    width: 100%;*/
    /*    height: auto;*/
    /*}*/

    /*.cardProject {*/
    /*    flex-direction: column;*/
    /*    justify-content: center;*/
    /*    width: calc(100% - 40px);*/
    /*    margin-bottom: 70px;*/
    /*}*/

/*    .wrapperDescription .title {*/
/*        font-size: 35px;*/
/*        padding-top: 10px;*/
/*    }*/

/*    .ourProjectTitle {*/
/*        text-align: center;*/
/*        padding: 0px 80px;*/
/*        margin: 80px auto 60px;*/
/*    }*/

/*    .cardProject .wrapperDescription {*/
/*        order: 2;*/
/*        padding: 0 80px;*/
/*    }*/

/*    .cardProject .wrapperDescription:nth-child(odd) {*/
/*        margin-right: 0px;*/
/*    }*/

/*    .cardProject .wrapperDescription:nth-child(even) {*/
/*        margin-left: 0px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 900px) {*/
/*    .ourProjectTitle {*/
/*        font-size: 70px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*    .ourProjectTitle {*/
/*        font-size: 39px;*/
/*    }*/

/*    .cardProject .wrapperDescription {*/
/*        padding: 30px 30px;*/
/*    }*/

/*    .ourProjects .cardProject {*/
/*        margin-bottom: 30px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*    ourProjectTitle {*/
/*        padding: 0;*/
/*        margin: 80px auto 29px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 400px) {*/
/*    .ourProjectTitle {*/
/*        font-size: 27px;*/
/*    }*/
/*}*/
