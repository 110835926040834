.Services {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;
}

.ServicesTitle {
    font-family: TT Commons;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 120%;
    color: #27262D;
    margin-top: 200px;
}

.ServicesDescription {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #27262D;
    padding: 90px 0 90px 720px;
    height: auto;
    max-width: 680px;
}

.ServicesDirection {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #27262d26;
    padding: 40px 0;
    cursor: pointer;
}

.ServicesDirection:hover img {
    margin-right: 0;
}

.ServicesDirection .name {
    width: 100%;
    font-family: InterBold;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: #27262D;
    letter-spacing: 0.03em;
}

.ServicesDirection img {
    transition: 400ms;
    width: 26px;
    height: 26px;
    margin-right: 16px;
}

@media screen and (max-width: 1300px) {

    .ServicesDescription {
        padding: 40px 0;
        max-width: none;
    }
}


@media screen and (max-width: 900px) {
    .ServicesTitle {
        font-size: 50px;
        margin-top: 100px;
    }

    .ServicesDirection {
        padding: 20px 0;
    }

    .ServicesDirection .name {
        font-size: 22px;
    }

    .ServicesDirection img {
        margin-right: 16px;
    }

    .Services {
        margin-bottom: 100px;
    }
}
