.footer {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background: #27262D;
}

.wrapperFooterBody {
    display: flex;
}

.footerContacts {
    margin-right: 40px;
}

.footerContactsMenuWrapper {
    display: flex;
}

.footerTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.footerSubTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.5;
    margin: 16px 0;
    cursor: pointer;
}

.footerMenu {
    min-width: 242px;
}

.footer svg path:nth-child(1) {
    stroke: white;
}

.footer svg path:nth-child(2) {
    fill: white;
}

.footer svg {
    margin-right: 702px;
}

.footerCopyright div {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.5;
}

.footerCopyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 90px;
}

@media screen and (max-width: 1200px) {
    .footer .contentWrapper {
        width: calc(100% - 40px);
    }

    .wrapperFooterBody {
        flex-direction: column;
    }

    .footerContactsMenuWrapper {
        margin-top: 30px;
    }

    .footer svg {
        width: 42px;
    }

    .footer {
        padding-top: 15px;
    }

    .footerMenu {
        min-width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .footer svg {
        margin-right: 0;
    }

    .footerContactsMenuWrapper {
        flex-direction: column;
    }

    .footerContacts {
        padding-bottom: 30px;
    }
}

