.servicesPageItem {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 150px 0 20px;
}

.servicesPageTitle {
    font-family: TTNormsBold;
    font-style: normal;
    font-weight: 800;
    font-size: 90px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #27262D;
    padding: 120px 0;
    border-bottom: 1px solid rgba(39, 38, 45, 0.15);
    border-top: 1px solid rgba(39, 38, 45, 0.15);
}

.wrapperTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardDescriptionWrapper .title {
    font-family: InterBold;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #27262D;
    margin: 40px 0 80px 0;
}

.cardDescriptionWrapper .description {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #27262D;
    margin-bottom: 90px;
    padding-right: 55%
}

.cardDescriptionWrapper .direction {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #27262D;
    padding: 17px 0;
    border-bottom: 1px solid rgba(39, 38, 45, 0.15);
}

.servicesPageItem .servCard img {
    position: absolute;
    bottom: 0;
    right: 0;
}

.servicesPageItem .servCard {
    position: relative;
    margin: 130px 0 150px;
}

@media screen and (max-width: 1200px) {
    .cardDescriptionWrapper {
        order: 2;
        margin: 0 auto;
    }

    .cardDescriptionWrapper .title {
        margin: 0px 0 10px 0;
    }

    .servicesPageItem .servCard img {
        position: static;
        order: 1;
        width: 80px;
        transform: none;
    }

    .servicesPageItem .servCard {
        margin: 50px 0 50px;
    }

    .cardDescriptionWrapper .description {
        padding-right: 25%;
        margin-bottom: 25px;
    }

    .servicesPageTitle {
        padding: 50px 0;
        text-align: center;
    }

    .servCard-img-1 {
        width: 100px !important;
    }

    .servCard-img-2 {
        width: 120px !important;
    }

    .servCard-img-3 {
        width: 65px !important;
    }
}

@media screen and (max-width: 900px) {
    .cardDescriptionWrapper .description {
        padding-right: 0%;
    }

    .servicesPageItem {
        margin: 30px 0;
    }

    .servicesPageTitle {
        font-size: 56px;
    }
}

@media screen and (max-width: 600px) {
    .cardDescriptionWrapper .title {
        font-size: 22px;
        margin: 20px 0 45px 0;
    }

    .cardDescriptionWrapper .description {
        font-size: 10px;
    }

    .cardDescriptionWrapper .direction {
        font-size: 12px;
        line-height: 16px;
        padding: 7px 0;
    }

    .servicesPageItem {
        margin: 0;
    }

    .servicesPageTitle {
        padding: 90px 0 30px;
    }
}

@media screen and (max-width: 400px) {
    .servicesPageTitle {
        font-size: 27px;
    }

    .servicesPageItem .servCard img {
        width: 60px;
    }

    .cardDescriptionWrapper .title {
        font-size: 18px;
        line-height: 23px;
    }

    .cardDescriptionWrapper .description {
        line-height: 110%;
    }

    .servCard {
        margin-bottom: 30px;
    }
}

.servCard-img-0 {
    right: 120px !important;
    bottom: -70px !important;
}

.servCard-img-1 {
    bottom: 70px !important;
    right: -60px !important;
}

.servCard-img-2 {
    right: -180px !important;
    bottom: -70px !important;
}

.servCard-img-3 {
    right: 190px !important;
    bottom: 80px !important
}

.servCard-img-4 {
    right: 130px !important;
    bottom: 110px !important
}
