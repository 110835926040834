.home-page {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

@media screen and (max-width: 1500px) {
    .home-page {
        padding: 0 20px;
        width: calc(100% - 40px);
    }
}
