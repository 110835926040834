.header {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.header .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.fixedMenu {
    position: fixed;
    width: 100%;
    top: 0;
    background: #ffffff;
    border-bottom: 1px solid rgba(39, 38, 45, 0.15);
}

.header img {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 1200px) {
    .header  {
        width: calc(100% - 40px);
        padding: 0 20px;
    }
}

@media screen and (max-width: 900px) {
    .FirstBlockName img {
        width: 500px;
    }
}



