.Projects {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 200px;
}

.ProjectCard {
    width: calc(100% / 2 - 40px);
}

.ProjectsCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.ProjectsCards img {
    width: 100%;
    max-width: 680px;
    height: auto;
    object-fit: cover;
    cursor: pointer;
}

.ProjectsTitle {
    font-family: TT Commons;
    font-style: normal;
    font-weight: bold;
    font-size: 90px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #27262D;
    margin-bottom: 90px;
}

.ProjectCard .title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #27262D;
    margin: 24px 0 8px;
}

.ProjectCard .subTitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #838383;
    margin-bottom: 80px;
}

.ProjectsSubTitle .text {
    font-family: TT Commons;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 34%;
    color: #27262D;
}

.ProjectsSubTitle {
    display: flex;
    margin-top: 10px;
    cursor: pointer;
}

.ProjectsSubTitle svg {
    width: 26px;
    height: 26px;
    margin-left: 16px;
    transition: 400ms;
}

.ProjectsSubTitle:hover img {
    margin-left: 40px;
}

.wrapper-show-real {
    width: 70vw;
}

@media screen and (max-width: 1100px) {
    .Projects {
        margin-top: 100px;
    }

    .show-real-button {
        display: none;
    }

    .wrapper-show-real {
        width: 100%;
    }
}


@media screen and (max-width: 900px) {
    .home-page-sub-title {
        padding: 140px 0 60px;
        font-size: 22px;
    }
    .text-logo {
        padding: 60px 0;
        width: 70vw;
    }
    .ProjectsTitle {
        padding: 0;
        font-size: 40px;
    }
}
